<template>
  <div>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <component-editor :component="entity" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import { VxCellButtons, VxTable } from '@/components/table'
import { VxButton } from '@/components/buttons'
import { useResource, components } from '@/services/resources'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { permissions, useCan } from '@/plugins/acl'
import { BModal } from 'bootstrap-vue'
import { useModalEditor } from '@/services/modal'
import { computed, ref } from '@vue/composition-api'
import ComponentEditor from '@/views/settings/ComponentEditor'

export default {
  name: 'Components',
  components: {
    VxTable,
    VxButton,
    VxCellButtons,
    BModal,
    ComponentEditor
  },
  setup () {
    const tableRef = ref(null)

    const resource = useResource(components.getAll)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const modalTitle = computed(() => {
      return (entity.value ? 'Disable' : 'Enable') + ' Component'
    })

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const canEdit = useCan(permissions.systemComponentUpdate)

    const actionButtons = ({ is_active }) => {
      const turnOff = { ...buttons.turnOff, label: 'Enabled', can: canEdit, onClick: openModal }
      const turnOn = { ...buttons.turnOn, label: 'Disabled', can: canEdit, onClick: openModal }

      return [{
        ...is_active ? turnOff : turnOn
      }]
    }

    const columns = [
      { key: 'id' },
      { key: 'name', label: 'Title' },
      { key: 'message' },
      {
        key: 'action',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      tableRef,

      resource,
      columns,

      modalIsOpen,
      entity,
      modalTitle,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
