<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.message"
      :rules="rules.message"
      name="message"
      label="Message"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { onBeforeMount, reactive } from '@vue/composition-api'
import { VxForm, VxInput } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { components, passDataToResource } from '@/services/resources'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'ComponentEditor',
  components: { VxForm, VxInput, VxButton },
  props: {
    component: {
      type: Object,
      default: null
    }
  },
  setup ({ component }, { emit }) {
    const formValue = reactive({
      message: ''
    })

    const frontToBackMapper = ({ message }) => {
      return {
        message,
        is_active: !component.is_active
      }
    }

    const formResource = passDataToResource(components.update, {
      frontToBackMapper,
      requestParams: {
        urlParams: { id: component.id }
      }
    })

    const rules = {
      message: {
        required: true,
        min: 2,
        max: 1000
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    onBeforeMount(() => setValuesToForm(component, formValue))

    return {
      rules,
      formValue,
      formResource,
      handleSubmit
    }
  }
}
</script>
